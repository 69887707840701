import {
    addElement, cancelOperationElement,
    removeInstances,
    RentElementsGridItemCustom,
    TimeTable,
    updateAnyTimetables, updateElement
} from "../../reducers/operationForm.reducer";
import {getStore, getStoreState} from "../../../../../../index";
import {Button, Icon, Popover} from "antd";
import {IconAngleDoubleRight, IconClose, IconEdit, IconEllipsisHSolid, IconEraser, IconReply} from "../../../../../components/icons";
import {joinClasses} from "../../../../../shared/util/utils";
import {OperationElementCreatePopover} from "../elementCreatePopover/OperationElementCreatePopover";
import {OperationElementEditPopover} from "../elementCreatePopover/OperationElementEditPopover";
import React, {useState} from "react";
import {
    loadNomenclaturesOnIntervalsIfNeed,
    NomenclatureOnInterval,
    rentElementToNomenclatureOnInterval
} from "../../utils/nomenclaturesUtils";
import {OperationFormUtils} from "../../utils/operationFormUtils";
import {NomenclatureRecord} from "../../../../../server";
import moment, {Moment} from "moment";
import {isRentOrSubrentOperation, isReturnOrSubrentReturnOperation} from "../../utils/utils";

interface OperationFormEquipmentListActionsPopoverProps {
    rowData: RentElementsGridItemCustom;
}

export const OperationFormEquipmentListActionsPopover = ({rowData}: OperationFormEquipmentListActionsPopoverProps) => {
    let state = getStoreState().operationForm;
    let element = state.elements.entities.find(el => el.id === rowData.id);
    let cancelled = rowData.isCancelled;//element && element.isCancelled ? element.isCancelled : false;

    const [visible, setVisible] = useState(false);

    return (
        <div className={'rr-grid-table-actions-group'}>
            <div>
                <Popover arrowPointAtCenter={true}
                         overlayClassName={'rr-actions-popover'}
                         placement="bottomRight"
                         trigger={'click'}
                         overlayStyle={{width: 300}}
                         visible={visible}
                        onVisibleChange={(visible)=>{
                            setVisible(visible);
                        }}
                         content={
                    <>
                        <div className={'rr-grid-actions-popover-header1'}></div>
                        <div className={'rr-grid-actions-popover-content'}>
                            {
                                !cancelled && <Button
                                    disabled={element && element.instanceCount === element.instanceCountOriginal}
                                    className={'ant-btn-page-actions'} onClick={async () => {
                                    addAll(rowData);
                                    setVisible(false);
                                }}>
                                    <Icon
                                        style={{cursor: 'pointer'}}
                                        component={IconAngleDoubleRight}
                                    />
                                    {'Добавить все'}
                                </Button>
                            }

                            {
                                <Button className={'ant-btn-page-actions'} onClick={async ()=> {
                                    cancelAll(rowData);
                                    setVisible(false);
                                }}>
                                    <Icon
                                        className={joinClasses('rr-OperationElementCancelToggleButton', cancelled ? 'rr-OperationElementCancelToggleButton-cancelled' : '')}
                                        style={{cursor: 'pointer', top: 0}}
                                        component={cancelled ? IconReply : IconEraser}
                                    />
                                    {!cancelled ? 'Отменить' : 'Вернуть в операцию'}
                                </Button>
                            }

                            {
                                !cancelled ? (
                                    element ? (
                                        <OperationElementCreatePopover
                                            elementRecord={element}
                                            disabled={!!rowData.kitId}
                                            mode={'edit'}
                                            instanceCount={element.instanceCount}
                                            availableInstanceCount={element.availableInstanceCount}
                                            onCloseCB={()=>{
                                                setVisible(false);
                                            }}
                                        >{
                                            (() => (
                                                <Button className={'ant-btn-page-actions'}>
                                                    <Icon
                                                        className={'rr-grid-table-edit-icon'}
                                                        style={{cursor: 'pointer', top: 0}}
                                                        component={IconEdit}
                                                    />
                                                    {'Редактировать'}
                                                </Button>
                                            ))
                                        }
                                        </OperationElementCreatePopover>
                                    ) : (
                                        <OperationElementEditPopover
                                            elementRecord={rowData}
                                            disabled={!!rowData.kitId}
                                            mode={'create'}
                                            instanceCount={rowData.instanceCount}
                                            availableInstanceCount={rowData.instanceCount}
                                            onCloseCB={()=>{
                                                setVisible(false);
                                            }}
                                        >{
                                            (() => (
                                                <Button className={'ant-btn-page-actions'}>
                                                    <Icon
                                                        className={'rr-grid-table-edit-icon'}
                                                        style={{cursor: 'pointer', top: 0}}
                                                        component={IconEdit}
                                                    />
                                                    {'Редактирование'}
                                                </Button>
                                            ))
                                        }
                                        </OperationElementEditPopover>
                                    )
                                ) : null
                            }

                            <Button disabled={!element} className={'ant-btn-page-actions'} onClick={()=>{
                                if(element) getStore().dispatch(removeInstances(rowData.id, element.instanceCount));
                                setVisible(false);
                            }}>
                                <Icon
                                    className={'rr-grid-table-remove-icon'}
                                    style={{cursor: 'pointer', top: 0}}
                                    component={IconClose}
                                />
                                {'Убрать из операции'}
                            </Button>

                        </div>
                    </>
                }>
                            <span>
                                <Icon className={'rr-grid-table-edit-icon'}
                                      style={{cursor: 'pointer', fontSize: 30}} component={IconEllipsisHSolid}/>
                            </span>
                </Popover>
            </div>
        </div>
    );
};


const addAll = async (rowData: RentElementsGridItemCustom) => {
    let state = getStoreState().operationForm;
    let element = state.elements.entities.find(el => el.id === rowData.id);

    let startDate:Moment|undefined;
    let endDate:Moment|undefined;
    if (state.actualizeDateMode) {
        if (isRentOrSubrentOperation(state.targetStateCode || state.typeCode)){
            startDate = moment(getStoreState().operationForm.operationStartTime).clone();
        }
        else if (isReturnOrSubrentReturnOperation(state.targetStateCode || state.typeCode)){
            endDate = moment(getStoreState().operationForm.operationStartTime).clone();
        }

        if(startDate || endDate){
            rowData = {...rowData, rentTerms: {...rowData.rentTerms, rentPeriodStartDate: startDate ? startDate.toDate() : rowData.rentTerms.rentPeriodStartDate, rentPeriodEndDate: endDate ? endDate.toDate() : rowData.rentTerms.rentPeriodEndDate}};
            if(rowData.parent) rowData.parent = {...rowData.parent, rentTerms: {...rowData.parent.rentTerms, rentPeriodStartDate: startDate ? startDate.toDate() : rowData.parent.rentTerms.rentPeriodStartDate, rentPeriodEndDate: endDate ? endDate.toDate() : rowData.parent.rentTerms.rentPeriodEndDate}};
        }
    }

    let nomenclatures:NomenclatureOnInterval[] = [rentElementToNomenclatureOnInterval(rowData)];
    if(rowData.parent) nomenclatures.push(rentElementToNomenclatureOnInterval(rowData.parent));
    nomenclatures.forEach((n)=>{
        if(n.until <= n.from) {
            n.until = n.from + 1;
        }
    });

    const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
    getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));

    let n = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[0].id && tt.type === nomenclatures[0].type);

    // let n = await OperationFormUtils.getNomenclaturesById([{
    //     productId: rowData.productId,
    //     variantId: rowData.variantId
    // }], true);
    if (n?.nomenclature) {
        // Нужно добавить к элементам
        let el = OperationFormUtils.createOperationElementFromElement(rowData, n.nomenclature as NomenclatureRecord);
        if (!element) {
            if (rowData.parent && state.elements.entities.find(el => rowData.parent && el.id === rowData.parent.id)) {
                el.parentId = rowData.parent.id;
                getStore().dispatch(addElement(el));
            }else{
                let kitN:TimeTable|undefined;
                if(rowData.parent){
                    // kitN = await OperationFormUtils.getNomenclaturesById([{
                    //     kitId: rowData.parent.kitId
                    // }], true);
                    kitN = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[1].id && tt.type === nomenclatures[1].type);
                }
                if(kitN?.nomenclature && rowData.parent){
                    let kitEl = OperationFormUtils.createOperationElementFromElement(rowData.parent, kitN.nomenclature as NomenclatureRecord);
                    el.parentId = rowData.parent.id;
                    getStore().dispatch(addElement([kitEl, el]));
                }else {
                    getStore().dispatch(addElement(el));
                }
            }
        } else {
            if(rowData.parent && state.elements.entities.find(el=>rowData.parent && el.id === rowData.parent.id)){
                el.parentId = rowData.parent.id;
            }
            getStore().dispatch(updateElement(el));
        }
    }
};

const cancelAll = async (rowData: RentElementsGridItemCustom) => {
    let cancelled = rowData.isCancelled;
    let state = getStoreState().operationForm;
    let element = state.elements.entities.find(el => el.id === rowData.id);

    let nomenclatures:NomenclatureOnInterval[] = [rentElementToNomenclatureOnInterval(rowData)];
    if(rowData.parent) nomenclatures.push(rentElementToNomenclatureOnInterval(rowData.parent));
    const data = await loadNomenclaturesOnIntervalsIfNeed(nomenclatures);
    getStore().dispatch(updateAnyTimetables(data.timetables, data.elementsDelayedReturnDates));

    if (!element) {
        let n = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[0].id && tt.type === nomenclatures[0].type);

        // let n = await OperationFormUtils.getNomenclaturesById([{
        //     productId: rowData.productId,
        //     variantId: rowData.variantId
        // }], true);
        if (n?.nomenclature) {
            // Нужно добавить к элементам
            let el = OperationFormUtils.createOperationElementFromElement(rowData, n.nomenclature as NomenclatureRecord);
            if (rowData.parent && state.elements.entities.find(el => rowData.parent && el.id === rowData.parent.id)) {
                el.parentId = rowData.parent.id;
                await getStore().dispatch(addElement(el));
            }else{
                let kitN:TimeTable|undefined;
                if (!element && rowData.parent) {
                    // kitN = await OperationFormUtils.getNomenclaturesById([{
                    //     kitId: rowData.parent.kitId
                    // }], true);
                    kitN = getStoreState().operationForm.timeTables.find((tt)=>tt.id === nomenclatures[1].id && tt.type === nomenclatures[1].type);
                }
                if (kitN?.nomenclature && rowData.parent) {
                    let kitEl = OperationFormUtils.createOperationElementFromElement(rowData.parent, kitN?.nomenclature as NomenclatureRecord);
                    el.parentId = rowData.parent.id;
                    await getStore().dispatch(addElement([kitEl, el]));
                } else {
                    await getStore().dispatch(addElement(el));
                }
            }
            getStore().dispatch(cancelOperationElement(rowData.id, true));
        }
    } else {
        getStore().dispatch(cancelOperationElement(rowData.id, !cancelled));
    }
};

